<template>
  <div
    class="misc-wrapper"
    style="background:white"
  >
    <b-link class="brand-logo">
      <b-img
        :src="require('@/assets/images/logo/logo.svg')"
        alt="Surfe logo"
        style="max-width:150px"
      />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <b-img
          :src="require('@/assets/images/gsheets-leadjet.png')"
          style="width:240px"
          class="mb-3"
        />

        <h2 class="mb-1">
          Discover our Google Sheets integration 🚀
        </h2>
        <p class="mb-3">
          Benefit from Surfe's unique productivity space, synced with GSheets.
        </p>

        <b-form
          inline
          class="row justify-content-center m-0 mb-4"
          @submit.prevent
        >
          <b-button
            :href="oauthLink"
            class="my-1 mt-2 btn-sm-block"
            type="submit"
            size="lg"
          >
            <b-img
              :src="require('@/assets/images/logo-google.png')"
              class="w-75"
            />
          </b-button>
        </b-form>

        <b-button
          v-b-toggle.use-custom-code
        >
          <u>Have custom code?</u>
        </b-button>
        <b-collapse id="use-custom-code">
          <b-form-input
            v-model="customCode"
            placeholder="Enter your custom code"
            class="mx-auto w-50"
            @input="saveCustomCode"
          />
        </b-collapse>

        <video
          style="width:100%; height:100%"
          autoplay
          loop
          muted
        >
          <source
            src="https://media.surfe.com/leadjet-cover-motion.mp4"
            type="video/mp4"
          >
        </video>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BCollapse, BFormInput, BLink, BForm, BButton, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
    BFormInput,
    BLink,
    BForm,
    BButton,
    BImg,
  },
  data() {
    return {
      customCode: '',
      oauthLink: `${process.env.VUE_APP_CORE_API_URL}/gsheets/oauth`,
    }
  },
  methods: {
    saveCustomCode() {
      localStorage.setItem('customCode', this.customCode)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
